@mixin font-size($font-size) {
    font-size: $font-size;
    font-size: calculateRem($font-size);
}

@mixin line-height($line-height) {
    line-height: $line-height;
    line-height: calculateRem($line-height);
}

@mixin font-family($font-family) {
    @if map-has-key($font-families, $font-family) {
        font-family: #{map-get($font-families, $font-family)};
    }
}

@mixin font-weight($font-weight) {
    @if map-has-key($font-weights, $font-weight) {
        font-weight: #{map-get($font-weights, $font-weight)};
    }
}

@mixin font($font-family, $font-size, $font-weight, $line-height) {
    @include font-family($font-family);
    @include font-size($font-size);
    @include font-weight($font-weight);
    @include line-height($line-height);
}

@mixin transition($property, $transition: default) {
    @if $transition == default {
        transition: #{$property} 0.2s ease;
    } @else {
        transition: #{$property} #{$transition};
    }
}

@mixin linearGradient($color1, $color2) {
    background: $color1;
    background-image: -moz-linear-gradient(top, $color1 0%, $color2 100%);
    background-image: -webkit-gradient(top, left top, right top, color-stop(0, $color1), color-stop(1, $color2));
    background-image: -webkit-linear-gradient(to bottom, $color1 0%, $color2 100%);
    background-image: -o-linear-gradient(top, $color1 0%, $color2 100%);
    background-image: -ms-linear-gradient(top, $color1 0%, $color2 100%);
    background-image: linear-gradient(to bottom, $color1 0%, $color2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(
            startColorstr="$color1",
            endColorstr="$color2",
            GradientType=0
    );
}
@mixin radialGradient($color1, $color2, $pattern: false) {
    background: $color1;
    @if $pattern == false {
        background-image: -moz-radial-gradient(
            center center,
            circle farthest-corner,
            $color1 0%,
            rgba($color2, 1) 100%
        );
        background-image: -webkit-radial-gradient(center center, circle farthest-corner, $color1 0%, $color2 100%);
        background-image: radial-gradient(circle at center, $color1 0%, $color2 100%);
    } @else {
        background-image: url('img/pattern.svg'),
            -moz-radial-gradient(center center, circle farthest-corner, $color1 0%, rgba($color2, 1) 100%);
        background-image: url('img/pattern.svg'),
            -webkit-radial-gradient(center center, circle farthest-corner, $color1 0%, $color2 100%);
        background-image: url('img/pattern.svg'), radial-gradient(circle at center, $color1 0%, $color2 100%);
    }
    filter: progid:DXImageTransform.Microsoft.gradient(
            startColorstr="$color1",
            endColorstr="$color2",
            GradientType=1
    );
}
// Breakpoint viewport sizes and media queries.
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
// (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.
// Name of the next breakpoint, or null for the last breakpoint.
// >> breakpoint-next(sm)
// md
// >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
// md
// >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
// md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}
// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
// >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
// 576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
}
// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
// >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
// 767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
    $next: breakpoint-next($name, $breakpoints);
    @return if($next, breakpoint-min($next, $breakpoints) - 0.02px, null);
}
// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash infront.
// Useful for making responsive utilities.
// >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
// ""  (Returns a blank string)
// >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
// "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, '', '-#{$name}');
}
// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}
// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}
// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($lower, $breakpoints);
    $max: breakpoint-max($upper, $breakpoints);
    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $max == null {
        @include media-breakpoint-up($lower, $breakpoints) {
            @content;
        }
    } @else if $min == null {
        @include media-breakpoint-down($upper, $breakpoints) {
            @content;
        }
    }
}
// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    $max: breakpoint-max($name, $breakpoints);
    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $max == null {
        @include media-breakpoint-up($name, $breakpoints) {
            @content;
        }
    } @else if $min == null {
        @include media-breakpoint-down($name, $breakpoints) {
            @content;
        }
    }
}
// Custom media breakpoint for tablet portrait
@mixin media-breakpoint-portrait($device, $breakpoints: $grid-breakpoints) {
    $bp: breakpoint-max(md, $breakpoints);
    @if $device == tablet {
        $bp: breakpoint-max(md, $breakpoints);
    }
    @if $device == phone {
        $bp: breakpoint-max(sm, $breakpoints);
    }
    @media (orientation: portrait) and (max-width: #{$bp}) {
        @content;
    }
}
// breadcrumb
@mixin breadcrumb-show-last-x($x) {
    .breadcrumb-item {
        &:nth-last-child(1):not(:first-child) {
            max-width: calc(100% / #{$x});
        }
        &:nth-last-child(#{$x}):not(:first-child) {
            @include breadcrumb-disable-dots;
            max-width: calc((100% / #{$x}) - (80px / (#{$x - 1})));
        }
        &:nth-last-child(#{$x + 1}):not(:first-child) {
            @include breadcrumb-enable-dots;
        }
        @if ($x == 1) {
            &:nth-child(1):nth-last-child(2),
            &:nth-child(2):nth-last-child(1) {
                &:first-child {
                    margin-right: 0;
                }
            }
        }
        @if ($x == 2) {
            &:nth-child(1):nth-last-child(3),
            &:nth-child(2):nth-last-child(2),
            &:nth-child(3):nth-last-child(1) {
                &:first-child {
                    margin-right: 0;
                }
            }
            &:nth-last-child(n + 4) {
                display: none;
            }
            &:nth-last-child(-n + 2) {
                color: getColor(gray);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        @if ($x == 3) {
            &:nth-child(1):nth-last-child(4),
            &:nth-child(2):nth-last-child(3),
            &:nth-child(3):nth-last-child(2),
            &:nth-child(4):nth-last-child(1) {
                &:first-child {
                    margin-right: 0;
                }
            }
            &:nth-last-child(2):not(:first-child) {
                max-width: calc(33% - 40px);
                width: auto;
            }
            &:nth-last-child(n + 5) {
                display: none;
            }
            &:nth-last-child(-n + 3) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: getColor(gray);
            }
        }
        &:first-child {
            display: inline-block;
        }
    }
}
@mixin breadcrumb-enable-dots {
    &::after {
        content: '...';
        color: getColor(gray);
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    &:hover {
        color: getColor(gray);
        cursor: pointer;
    }
    .breadcrumb-item-link {
        display: none;
    }
}
@mixin breadcrumb-disable-dots {
    &::after {
        content: normal;
    }
    .breadcrumb-item-link {
        display: inline;
    }
}
