input {
    -webkit-border-radius: 0;

    &.form-control {
        @include font(body, 14px, regular, 20px);
        border: 1px solid getColor(gray, border);
        color: getColor(black);
        padding: 8px 12px;
        border-radius: 8px;
        display: inline-block;

        &::placeholder {
            color: getColor(green, placeholder);
        }

        &:-ms-input-placeholder {
            color: getColor(green, placeholder) !important;
        }

        &.border {
            @include transition(all);
            border: 1px solid getColor(gray, light);
        }

        &:focus,
        &:active {
            outline: none;
            box-shadow: none;
            border-color: rgba(getColor(gray), 0.6);
        }

        &:disabled,
        &:read-only {
            background-color: rgba(getColor(gray), 0.1);
            cursor: not-allowed;
        }

        &.datepicker {
            background-color: inherit;
            cursor: text;
        }

        &.sm {
            width: 150px;
        }
    }

    &[type='button'],
    &[type='submit'] {
        cursor: pointer;
    }

    &[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0;
    }
}
