.btn {
    color: getColor(white);
    background-color: getColor(darkgreen);
    border-radius: 100px;
    padding: 10px 25px;
    text-decoration: none;

    -webkit-appearance: none;
    @include font-family(body);
    @include font-size(17px);
    @include transition(background);
    @include line-height(17px);
    font-weight: normal;
    border: none;
    width: auto;
    display: inline-block;
    margin-bottom: 15px;

    &.disabled,
    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active,
    &:hover {
        background-color: #1e412a;
    }

    &:disabled,
    &.disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }

    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    }

    [class^='icon-'] {
        & + span {
            margin-left: 15px;
        }
    }

    span {
        & + [class^='icon-'] {
            margin-left: 15px;
        }
    }
}

button {
    cursor: pointer;

    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}
