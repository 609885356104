footer {
    margin-top: 115px;
    width: 100%;
    height: 2.5rem;

    .logo-box {
        width: 100%;
    }

    .logo {
        display: block;
        margin: auto;
        width: fit-content;
        img {
            display: flex;
            margin: auto;
            width: 120px;
            height: 100%;
        }
    }

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .footer-content-box {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            .link-list {
                height: 80px;
                width: 100%;
                margin-top: 8px;
                li {
                    .footer-icon {
                        position: absolute;
                        transition: filter 0.2s ease;
                        width: 18px;
                        height: 18px;
                        margin-right: -18px;
                        margin-bottom: -4px;
                        filter: none;
                        z-index: -1;
                        margin-top: 3px;
                    }

                    @include font-family(body);
                    display: inline;
                    margin: 0 calculateRem(5px);
                    font-size: 15px;

                    a {
                        padding-left: 20px;
                        transition: color 0.2s ease;
                        color: getColor(darkgreen);
                        text-decoration: none;
                    }

                    a:hover {
                        padding-left: 20px;
                        transition: color 0.2s ease;
                        color: getColor(green);
                    }

                    &:hover .footer-icon {
                        position: absolute;
                        filter: saturate(5);
                        z-index: -1;
                        margin-top: 3px;
                    }
                }
            }

            &:last-child {
                text-align: center;
                flex-direction: column;
            }
        }
    }

    .bottom-bars {
        margin-top: 1vw;
        .bargreen {
            position: absolute;
            margin-top: -20px;
            z-index: -1;
            width: 100%;
            height: 45px;
            background-color: getColor(green);
        }

        .bargold {
            position: absolute;
            margin-top: -30px;
            z-index: -2;
            width: 100%;
            height: 50px;
            background-color: getColor(gold);
        }

        .bar18 {
            font-size: 15px;
            font-style: italic;
            width: 100%;
            background-color: getColor(darkgreen);
            position: fixed;
            bottom: 0;
            height: 35px;

            .nix-18-logo {
                height: 15px;
            }

            .container {
                font-family: arial;
                color: getColor(white);
                margin: 6px auto auto;
                width: 60%;

                a {
                    color: getColor(white);
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .link-list {
            height: 80px;
            width: 100%;
            margin-top: 8px;
        }
        .bottom-bars {
            margin-top: 1vw;
            font-size: 4px;
            color: red;
            .bar18 {
                height: 50px;
                font-size: 13px;
                line-height: 15px;
            }
            .bargold {
                height: 55px;
            }
            .bargreen {
                height: 60px;
            }
        }
        .footer-content-box {
            margin-bottom: 30px;

            .list-inline {
                padding-left: 0;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .bottom-bars {
            margin-top: 3vw;
        }
    }
}
