label.select {
    border: 1px solid getColor(gray, border);
    border-radius: 8px;
    position: relative;
    z-index: 0;
    cursor: pointer;

    &.md {
        width: 150px;
    }

    &.lg {
        width: 300px;
    }

    &.full {
        width: 100%;
    }

    &:after {
        line-height: 100%;
        content: '\f0d7';
        font-family: 'icomoon';
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: -1;
        height: 100%;
        cursor: pointer;
        color: getColor(gray, border);
    }

    select {
        //@include font-family(body);
        //@include font-size(15px);
        @include font(body, 14px, regular, 20px);
        border: none;
        box-shadow: none;
        background-color: transparent;
        //background-image: none;
        appearance: none;
        padding: 8px 32px 8px 16px;
        width: 100%;
        cursor: pointer;
        color: getColor(darkgreen);

        appearance: none;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        background-position: right 0.7rem top 50%;
        background-size: 0.65rem auto;

        &:focus {
            outline: none;
        }

        option[value=''][disabled] {
            display: none;
        }

        option[value=''][disabled][selected] {
            color: getColor(gray, placeholder);
        }

        &[disabled] {
            color: getColor(gray, placeholder);
        }

        &::-ms-expand {
            display: none;
        }
    }
}
