.container {
    width: 100%;
    max-width: 1200px;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
    position: relative;

    @include media-breakpoint-down(sm) {
        width: 100% !important;
        max-width: 100% !important;
    }

    @include media-breakpoint-up(md) {
        max-width: 720px;
    }

    @include media-breakpoint-up(lg) {
        max-width: 960px;
    }

    @include media-breakpoint-up(xl) {
        max-width: 1140px;
    }

    @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
    }

    @include media-breakpoint-portrait(tablet) {
        padding-right: 30px;
        padding-left: 30px;
    }

    @include media-breakpoint-down(sm) {
        padding-right: 20px;
        padding-left: 20px;
    }
}
