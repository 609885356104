div.agegate {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    overflow: auto;
    height: 100vh;
    width: 100%;
    display: none;

    .agegate-content {
        padding: 0 0 calculateRem(120px);
        text-align: center;
        margin: 0 auto;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-image: url('../dist/img/agegate.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;

        .agegate-box {
            border-radius: 15px;
            overflow: hidden;
            color: getColor(darkgreen);
            background-color: getColor(gold);
            width: auto;

            .agegate-logo {
                justify-self: center;
                align-self: end;
                .logo {
                    width: 100px;
                    height: 100%;
                }
                grid-column: 1 / 1;
                grid-row: 1 / 3;
            }

            .agegate-top {
                background-color: getColor(gold);
                padding: 30px;

                h1 {
                    color: getColor(white);
                    text-align: left;
                    margin: 0;
                    @include font-size(26px);
                }

                p {
                    color: getColor(white);
                    text-align: left;
                    margin: 0;
                }

                @media only screen and (max-width: 600px) {
                    p {
                        line-height: calculateRem(24px);
                    }
                }
            }

            .agegate-bottom {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-template-rows: 1fr 1fr 1fr;
                background-color: getColor(white);
                justify-items: start;
                align-items: center;
                column-gap: calculateEm(12px);
                row-gap: calculateEm(8px);
                padding: calculateEm(8px);

                @media only screen and (max-width: 600px) {
                    grid-template-columns: repeat(3, auto);
                    .agegate-logo {
                        display: none;
                    }
                    .form-check {
                        grid-column-start: 1;
                    }
                    .submit-agegate {
                        grid-row-start: 3;
                        grid-column-start: 3;
                    }
                    .form-control {
                        min-width: 50px;
                        max-width: 100px;
                    }
                }
            }

            form {
                label.field-label {
                    grid-row: 1 / 1;
                    font-weight: bold;
                    align-self: end;
                }

                .form-control {
                    grid-row: 2/2;
                    height: 50px;
                    border-style: solid;
                    border-radius: 10px;
                    border-width: 1px;
                    border-color: getColor(darkgreen);
                    text-align: center;
                    @include font-family(body);
                    font-size: calculateRem(18px);

                    &::placeholder {
                        color: getColor(darkgreen);
                    }

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }

                .submit-agegate {
                    width: auto;
                    height: 50px;
                    background-color: getColor(darkgreen);
                    color: getColor(white);
                    @include font-family(body);
                    font-size: calculateEm(18px);
                    font-weight: bolder;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    text-align: center;

                    outline: none;
                    border: none;
                    border-radius: 25px;

                    &::placeholder {
                        color: getColor(darkgreen);
                    }
                }

                .submit-agegate {
                    grid-row: 2 / 3;
                    width: 100%;
                }
            }

            .agegate-or {
                margin: calculateRem(15px) 0;
                display: inline-block;

                &:before,
                &:after {
                    content: '\2014';
                    color: getColor(gray, light);
                    margin: 0 calculateRem(5px);
                }
            }

            .error {
                @include transition(all);
                @include font-family(medium);
                font-size: calculateRem(18px);
                opacity: 0;
                color: getColor(red);
                padding: 0;
                grid-column: 3 / 5;
                grid-row: 3;

                &.active {
                    opacity: 1;
                    margin: 0 0 calculateRem(15px) 0;
                }
            }

            .form-check {
                @include font-family(body);
                font-size: calculateRem(14px);
                font-weight: normal;
                grid-column: 2;
                grid-row: 3;
            }
        }
    }

    .noalcohol {
        text-align: center;
        padding: calculateRem(15px) 0;
        font-size: calculateRem(15px);
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}
