@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(getColor(gray), 0.6);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(getColor(gray), 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(getColor(gray), 0);
    }
}

@keyframes swipe {
    0%,
    20%,
    80%,
    100% {
        transform: translateX(-10px);
    }
    40%,
    60% {
        transform: translateX(10px);
    }
}
