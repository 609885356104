nav {
    .nav-wrapper {
        ul {
            &.navigation {
                display: flex;
                flex-direction: row;
                align-items: center;

                li {
                    display: inline-block;
                    margin: 0 calculateRem(15px);

                    &.active {
                        a:not(.search) {
                            &:before {
                                width: 100%;
                            }
                        }
                    }

                    &:hover {
                        a:not(.search) {
                            &:before {
                                width: 100%;
                            }
                        }

                        a {
                            text-decoration: none;
                        }
                    }

                    &:last-child {
                        margin: 0;
                    }

                    a {
                        @include font-family(body);
                        text-decoration: none;
                        color: getColor(darkgreen);
                        padding: 0;
                        position: relative;
                        font-size: 1.2rem;
                    }

                    a:hover {
                        transition: color 0.2s ease;
                        color: #1e7e34;
                    }
                }
            }

            &.social {
                display: none;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .nav-wrapper {
            @include transition(all);
            display: block;
            opacity: 1;
            background: getColor(white);
            position: fixed;
            top: 0;
            right: 0;
            width: 85%;
            height: 100vh;
            overflow: auto;
            padding-bottom: calculateRem(30px);
            transform: translateX(100%);
            z-index: 3;

            ul.navigation {
                margin-top: $nav-height;
                display: block;

                li {
                    display: block;
                    background-color: getColor(white);
                    margin: 0;
                    text-align: center;

                    a {
                        padding: calculateRem(25px) 0;
                        display: inline-block;
                        font-size: 3rem;
                        padding-left: 25px;
                        padding-right: 25px;
                        line-height: 2em;

                        &:before {
                            bottom: calculateRem(12px);
                        }

                        @media only screen and (orientation: landscape) {
                            line-height: 3px;
                            font-size: 2em;
                        }
                    }

                    &.active {
                        a {
                            &:before {
                                bottom: calculateRem(10px);
                            }
                        }
                    }
                }
            }

            .nav-expanded & {
                transform: translateX(0);
            }

            .hamburger {
                font-size: 3rem; /* Adjust the font size for the scalable hamburger icon */
            }
        }
    }
}
