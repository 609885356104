section.contact-form {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;

    .privacy-text {
        font-size: 14px;
    }

    .alert {
        margin: 10px 0;

        &.error {
            border: 1px solid getColor(red);
            border-radius: 3px;
        }
        &.success {
            border: 1px solid getColor(green);
            border-radius: 3px;
        }
        &.warning {
            background-color: orange;
        }
    }

    form {
        .form-control {
            min-width: 125px;
        }

        @include media-breakpoint-down(lg) {
            max-width: 100%;
        }

        @include media-breakpoint-portrait(tablet) {
            width: 100%;

            .row {
                margin-bottom: 0;

                .form-control-label {
                    width: 100%;
                    flex: none !important;
                    margin-bottom: 10px;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            width: 100%;

            .row {
                margin-bottom: 0;

                .form-control-label {
                    width: 100%;
                    flex: none !important;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
