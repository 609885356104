.text-only {
    display: -ms-grid;
    display: grid;
    grid-column-gap: 30px;

    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;

    grid-template-areas: 'titles textonly' 'content textonly';

    margin: 10px 0 10px 0;

    .text-only__title {
        -ms-grid-row: 1;
        -ms-grid-column: 1;

        grid-area: titles;
        align-self: end;
        -ms-grid-row-align: end;
    }

    .text-only__content {
        position: relative;
        -ms-grid-row: 2;
        -ms-grid-column: 1;

        grid-area: content;
    }

    .text-only__cta {
        color: getColor(white);
        background-color: getColor(darkgreen);
        border-radius: 100px;
        padding: 10px 25px;
        text-decoration: none;
    }

    @include media-breakpoint-down(lg) {
        &.has-scroll-animation {
            .text-only__title {
                padding-top: 0;
            }
            .text-only__content {
                padding-bottom: 0;
            }
        }

        &.product,
        &.added-padding {
            .text-only__title,
            .text-only__content {
                padding: 0;
            }
        }
    }

    @include media-breakpoint-portrait(tablet) {
        &.has-scroll-animation {
            .text-only__title {
                padding-top: 0;
            }
            .text-only__content {
                padding-bottom: 0;
            }
        }

        &.product,
        &.added-padding {
            .text-only__title,
            .text-only__content {
                padding: 0;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        grid-row-gap: calculateRem(15px);

        -ms-grid-rows: auto auto auto;
        grid-template-rows: auto auto auto;

        -ms-grid-columns: auto;
        grid-template-columns: auto;

        grid-template-areas: 'titles' 'content';

        .text-only__title {
            h1 {
                margin-bottom: 0;
            }
        }

        &.has-scroll-animation {
            .text-only__title {
                padding-top: 0;
            }
            .text-only__content {
                padding-bottom: 0;
            }
        }

        .text-only__content {
            .btn {
                margin: 0 auto;
            }

            &.signature {
                background-image: none;
            }
        }

        &.product {
            background-image: none;

            .text-only__title,
            .text-only__content {
                padding: 0;
            }
        }
    }
}
