textarea {
    -webkit-border-radius: 0;

    &.form-control {
        @include font(body, 14px, regular, 20px);
        border: 1px solid getColor(gray, border);
        color: getColor(black);
        padding: 8px 16px;
        border-radius: 8px;
        resize: none;
        display: block;

        &::placeholder {
            color: getColor(gray, light);
        }

        &:-ms-input-placeholder {
            color: getColor(gray, light) !important;
        }

        &:focus,
        &:active {
            outline: none;
            box-shadow: none;
            border-color: rgba(getColor(gray), 0.6);
        }

        &:disabled {
            background-color: rgba(getColor(gray), 0.1);
            cursor: not-allowed;
        }

        &.full {
            width: 100%;
        }
    }
}
