form {
    &.processing {
        &:before {
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            background-color: rgba(getColor(white), 0.8);
            top: 0;
            left: 0;
            z-index: 2;
        }

        &:after {
            content: '';
            height: 30px;
            width: 30px;
            border: 4px solid rgba(getColor(gray, border), 0.8);
            border-top: 4px solid getColor(green, base);
            border-radius: 30px;
            animation: spin 0.5s infinite cubic-bezier(0.42, 0, 1, 1);
            position: absolute;
            left: calc(50% - 15px);
            top: calc(50% - 15px);
            z-index: 3;
        }

        @include media-breakpoint-down(sm) {
            &:before {
                background-color: rgba(getColor(gray, light), 0.8);
                height: 104%;
                width: 104%;
                top: -2%;
                left: -2%;
            }
        }
    }
}

form,
.form {
    .form-group {
        position: relative;

        &.inline {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            //margin-bottom: 15px;

            &.full {
                width: 100%;
            }

            .form-control-label {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }

                label {
                    @include font-weight(bold);
                    display: block;
                    margin-bottom: 3px;
                }

                &.f1 {
                    flex: 1;
                }

                &.f2 {
                    flex: 2;
                }

                &.f3 {
                    flex: 3;
                }

                &.f4 {
                    flex: 4;
                }

                .form-control {
                    width: 100%;
                }
            }

            input {
                margin-top: 0;
                margin-right: 10px;

                &:last {
                    margin-right: 0;
                }
            }
        }

        .btn {
            margin-bottom: 0;
        }

        &.append-button {
            display: flex;
            input {
                width: 100%;
                margin: 0;
            }
            button {
                white-space: nowrap;
                margin-left: -15px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-down(sm) {
            &.inline {
                input {
                    margin-right: 0;
                }
            }
        }
    }
}
