@font-face {
    font-family: 'DaxCondensed';
    src: url('DaxCondensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'DaxCondensed-Medium';
    src: url('DaxCondensed-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
