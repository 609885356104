.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;

    &:last-child {
        margin-bottom: 0;
    }

    .col {
        flex: 1;

        &.f1 {
            flex: 1;
        }

        &.f2 {
            flex: 2;
        }

        &.f3 {
            flex: 3;
        }

        &.f4 {
            flex: 4;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;

        .col {
            flex: 1 1 auto;
            padding: 0 15px;

            &.f1,
            &.f2,
            &.f3,
            &.f4 {
                flex: 1 1 auto;
            }
        }
    }

    @include media-breakpoint-portrait(tablet) {
        flex-direction: column;

        .col {
            flex: 1 1 auto;
            margin: 0 0 15px 0 !important;

            &.f1,
            &.f2,
            &.f3,
            &.f4 {
                flex: 1 1 auto;
            }
        }
    }

    @include media-breakpoint-portrait(phone) {
        flex-direction: column;

        .col {
            flex: 1 1 auto;
            margin: 0 0 15px 0 !important;

            &.f1,
            &.f2,
            &.f3,
            &.f4 {
                flex: 1 1 auto;
            }
        }
    }
}

*,
::after,
::before {
    box-sizing: border-box;
}
