.mgnlEditor {
    text-transform: none;
    letter-spacing: 0;
}

section.highlights {
    &.mgnlEdit {
        display: block;

        .highlight {
            display: inline-block;
            float: left;
            margin: 0 15px;
        }
    }
}

section.articles {
    .mgnlEdit {
        display: block;

        .article {
            display: inline-block;
            float: left;
            margin: 0 15px;
        }
    }
}

.text-image {
    .mgnlEditorBar {
        position: absolute !important;
        top: 0;
        transform: translateY(100%);
    }
}
