.visible-sm {
    @include media-breakpoint-up(sm) {
        display: none;
    }
}

.hidden-sm {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
}

@include media-breakpoint-down(md) {
    .column-reverse-mobile {
        display: flex;
        flex-direction: column-reverse;
    }
}
