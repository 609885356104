.hamburger {
    display: none;
    width: 32px;
    height: 32px;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    border: 0;
    position: fixed;
    right: calculateRem(15px);
    z-index: 1000;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:active,
    &:focus {
        outline: 0;
    }

    .bars {
        position: relative;
        height: 100%;
        width: 100%;
        border: 1px solid transparent;

        span {
            height: 2px;
            background: #004008;
            display: block;
            position: absolute;
            width: 30px;
            left: 0;
            transition: all 0.15s ease;
            transform-origin: 0% 50%;

            &.top {
                top: calc(25% + 2px);
            }
            &.bottom {
                bottom: calc(25% + 2px);
            }
        }
    }

    &.active {
        span {
            width: 26px;
            left: 6px;

            &.top {
                top: 5px;
                transform: rotate(45deg);
            }
            &.bottom {
                bottom: 5px;
                transform: rotate(-45deg);
            }
        }
    }

    @include media-breakpoint-down(md) {
        display: block;
        right: calculateRem(30px);
    }

    @include media-breakpoint-down(sm) {
        right: calculateRem(20px);
    }
}
