form {
    .form-control {
        &.error {
            border-color: getColor(red);
        }
    }

    .form-check {
        .form-checkbox.error {
            & + label.error {
                //@extend .small;
                color: getColor(red) !important;
            }
        }
    }

    label.error {
        //@extend .small;
        background-color: getColor(red);
        color: getColor(white);
        padding: 4px 8px;
        margin: -2px 0 0 0 !important;
        border-radius: 0 0 3px 3px;
        display: block;
        width: 100%;
    }

    label#pickUp-error {
        display: inline-block;
        width: auto;
    }

    .alert {
        border-radius: 3px;
        text-align: left;
        margin: 0 0 15px 0;
        padding: 10px 15px;

        &.error {
            background-color: lighten(getColor(red), 50%);
        }
        &.success {
            background-color: lighten(getColor(darkgreen), 80%);
        }
        &.warning {
            background-color: getColor(orange);
        }
    }
}
