header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-top: 12px;
    max-width: 59%;
    align-self: center;
    margin: auto;
    z-index: 1;

    .logo-wrapper {
        width: 110px;

        .logo {
            width: 110px;
            height: 100%;
            display: inline-block;

            img {
                width: 110px;
                height: 100%;
            }
        }
    }

    a {
        @include font-family(body);
        transition: color 0.2s ease;
        color: #004008;
        font-size: 19px;
        padding-left: 12px;
        padding-right: 12px;
        text-decoration: none;
    }

    a:hover {
        transition: color 0.2s ease;
        color: #1e7e34;
    }

    ul {
        list-style: none;
    }

    li {
        display: inline-block;
        margin-right: 10px; /* add spacing between items */
    }

    @include media-breakpoint-down(lg) {
    }

    @include media-breakpoint-down(md) {
        .nax-expanded nav .nav-wrapper {
            display: block;
            transform: translateX(0);
        }

        nav .nav-wrapper {
            display: block;
            transform: translateX(100%);
        }

        padding: calculateRem(8px) calculateRem(15px);
        border-bottom: 1px solid getColor(gray, border);
        background: getColor(white);
        position: fixed !important;
        top: 0;
        left: 0;

        &.container {
            max-width: 100%;
            width: 100%;
            height: $nav-height;
        }

        > .logo-wrapper {
            width: 100%;
            height: auto;

            .logo {
                width: fit-content;
                display: flex;
                margin: auto;
                img {
                    display: flex;
                    width: auto;
                    margin: 1px auto auto auto;
                    height: auto;
                }
            }
        }
    }
}

.swoosh {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;

    @media (max-width: 1380px) {
        visibility: hidden;
    }

    @include media-breakpoint-down(md) {
        visibility: hidden;
    }

    img {
        width: 7.5vw;
        height: auto;
    }
}
